import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    firebaseConfig: {
      apiKey: process.env.VUE_APP_GI_API_KEY,
      authDomain: process.env.VUE_APP_GI_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_GI_PROJECT_ID,
      storageBucket: process.env.VUE_APP_GI_STORAGE_BUCKET,
      appId: process.env.VUE_APP_GI_APP_ID,
    },
    fireBaseApp: null,
    auth: null,
    authUser: null,
    authChecked: false,
    host: process.env.VUE_APP_HOST,
    clientId: process.env.VUE_APP_CLIENT_ID,
    authHost: process.env.VUE_APP_EDUCACIONIT_AUTH_HOST
  },
  mutations: {
    setFirebaseApp(state, app) {
      state.fireBaseApp = app;
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
    setAuthUser(state, user) {
      state.authUser = user;
    },
    setAuthChecked(state, value) {
      state.authChecked = value;
    },
  },
  actions: {
    async authRedirect({commit, state}, action = 'authorize' || 'logout'){
      let fullPath = router.history.current.fullPath
      const match = fullPath.match(/redir=([^&]+)/);

      if(match){
        fullPath = decodeURIComponent(match[1])
      }

      // eslint-disable-next-line vue/max-len
      window.location.href = `${state.authHost}/${action}?redirect_uri=${state.host}/auth-return&client_id=${state.clientId}&state=${fullPath}`;
    },
    async initializeFirebase({ commit, state }) {
      const app = initializeApp(state.firebaseConfig);
      commit('setFirebaseApp', app);
      commit('setAuth', getAuth(app));
    },
    async checkAuthRoute({dispatch}, user){
      if (user) return

      if (router.history.current.name !== 'Auth') {
        await dispatch('authRedirect', 'authorize')
      }
    },
    async logOut({ state }) {
      window.location.href = `${state.authHost}/logout?return_to=${state.host}&client_id=${state.clientId}`;
    },
    async signOut({ state }) {
      if (!state.auth) return;
      try {
        await signOut(state.auth);
      } catch (error) {
        console.log({ error });
      }
    },
    async authInit({ commit, state }) {
      return new Promise((resolve) => {
        onAuthStateChanged(state.auth, async (user) => {
          commit('setAuthUser', user);
          commit('setAuthChecked', true);
          resolve(user);
        });
      });
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.authUser,
  },
}
