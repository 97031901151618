import api from '@/services/api/api';
import axios from 'axios';

const initialState = () => ({
  fetching: null,
  error: null,
  calendars: [],
  success: null,
  userPendingCalendars: []
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    RESET_STATE(state){
      state.fetching = null
      state.error = null
      state.calendars = []
      state.success = null
    },
    FETCHING(state, value){
      state.fetching = !!value;
    },
    FETCH_SUCCESS(state, value){
      state.success = !!value;
    },
    FETCH_ERROR(state, value){
      state.error = value;
    },
    SET_CALENDAR_LIST(state, values){
      state.calendars = values;
    },
    SET_USER_CALENDAR_LIST(state, values){
      state.userPendingCalendars = []

      values.forEach(calendar => {
        if(calendar.pendiente){
          state.userPendingCalendars.push(calendar.curso_id)
        }
      })
    }
  },
  actions: {
    async fetch({ commit }, args){
      commit('RESET_STATE');
      commit('FETCHING', true);

      const params = {
        pais_id: 'AR',
        sin_completos: 1,
        cursos_ids: args.course
      }

      if(args.start){
        params['fecha_desde'] = args.start
      }

      return await axios
        .get(process.env.VUE_APP_EDUCACIONIT_API_SERVER + "public/calendarios", {params})
        .then(response => {
          commit('FETCH_SUCCESS', true);
          commit('FETCHING', false);
          commit('SET_CALENDAR_LIST', response.data);
          return response;
        })
        .catch(error => {
          commit('FETCH_SUCCESS', false);
          commit('FETCHING', false);

          if(404 === error.response.status){
            commit('SET_CALENDAR_LIST', []);
            return error.response;
          }

          commit('FETCH_ERROR', error);

          return error.response;
        });
    },
    async reset({ commit }){
      commit('RESET_STATE');
    },
    async getForUser({ commit }) {
      const response = await api.get('/plugin/calendar/user');
      commit('SET_USER_CALENDAR_LIST', response.data.data);
      return response.data.data;
    }
  },
  getters: {
    fetching: (state) => state.fetching,
    error: (state) => state.error,
    calendars: (state) => state.calendars,
    success: (state) => state.success,
    userPendingCalendars: (state) => state.userPendingCalendars
  },
};
