import api from '@/services/api/api';
import Api from '@/services/Api';
import store from '@/store'
import cacheStorage from '@/services/api/cache';

const initialState = () => ({
  authenticated: false,
  user: { certificates: [] },
  avatar: null,
  exam: null,
  guest: null,
  credits: null,
  contactos_calendarios: null,
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    contactos_calendarios: (state) => state.contactos_calendarios,
    user: (state) => state.user
  },
  mutations: {
    login(state, credentials) {
      cacheStorage.saveItem('application/token', credentials.token);
    },
    setSession(state, payload) {
      state.authenticated = true;
      state.user = { ...state.user, ...payload.data };
      state.exam = payload.exam;
      state.guest = payload.guest;
      state.credits = payload.credits;
      state.contactos_calendarios = payload.contactos_calendarios

      /**
       * If there is no avatar or the avatar needs to be refreshed (due to a profile update)
       * then refresh the avatar
       */
      if (!state.avatar || (typeof payload.refreshAvatar !== 'undefined' && payload.refreshAvatar)) {
        state.avatar = state.user.avatar;
      }
    },
    logout(state) {
      Object.assign(state, initialState());
      cacheStorage.remove('application/token');
    },
    setCertificates(state, payload) {
      state.user.certificates = payload;
    },
  },
  actions: {
    // eslint-disable-next-line
    async recaptcha(_, action) {
      return new Promise((res) => {
        // eslint-disable-next-line
        grecaptcha.ready(() => {
          // eslint-disable-next-line
          grecaptcha
            .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action })
            .then((token) => res(token));
        });
      });
    },
    async login({ commit, dispatch }, payload = {}) {
      commit('logout');

      const params = new URLSearchParams();

      params.append('username', payload.username);
      params.append('password', payload.password);

      const captcha = await dispatch('recaptcha', 'login');

      params.append('captcha', captcha);

      const { data } = await api.post('/auth/authenticate', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      commit('login', data);
      commit('setSession', data);

      return data;
    },
    async isAuthenticated({ state, commit }) {
      if(state.authenticated){
        return true;
      }

      if(!cacheStorage.getItem('application/token') && !store.state.AuthFirebase.authUser){
        return false;
      }

      try {
        const { data } = await api.get('/auth/session');
        commit('setSession', data);
        return true;
      } catch (err) {
        commit('logout');
        return false;
      }
    },

    async logout({ commit }) {
      commit('services/api/server/date/CLEAR_INTERVAL', null, { root: true });
      try {
        await api.get('/auth/logout');
      } catch (err) {
        console.log(err);
      }
      commit('logout');
    },

    keepAlive(_, { interval }) {
      setInterval(() => {
        api.get('/auth/refresh');
      }, interval * 60000);
    },

    async fetchCertificates({ commit }, contactoId) {
      try {
        const { data } = await Api.get(`/contactos/${contactoId}/certificados`);
        const certificates = data.data;
        if (certificates.length) commit('setCertificates', certificates);
      } catch (err) {
        commit('setCertificates', []);
      }
    },
  },
};
