import Store from '@/store';

const HOME = {
  path: '/',
  redirect: { name: 'Login' },
};

const LOGIN = {
  name: 'Login',
  path: '/login',
  component: () => import(/* webpackChunkName: "Login" */ '@/pages/Login'),
  meta: {
    requiresAuth: false,
  },
};

/*const NEW_LOGIN = {
  name: 'NewLogin',
  path: '/nl',
  component: () => import(/!* webpackChunkName: "Login" *!/ '@/pages/NewLogin'),
  meta: {
    requiresAuth: false,
    history: false,
  },
};*/

const AUTH = {
  name: 'Auth',
  path: '/auth-return',
  component: () => import(/* webpackChunkName: "Auth" */ '@/pages/Auth'),
  meta: {
    requiresAuth: false,
  },
};

const LOGOUT = {
  name: 'Logout',
  path: '/logout',
  beforeEnter: (to, from, next) => {
    const logoutFirebase = Store.dispatch('AuthFirebase/signOut');
    const logoutAuth = Store.dispatch('Auth/logout');

    Promise.all([logoutFirebase, logoutAuth])
      .then(() => next({ name: 'Login' }));
  },
  meta: {
    requiresAuth: true,
  },
};

const LOST_PASSWORD = {
  name: 'LostPassword',
  path: '/password/recover',
  component: () => import(/* webpackChunkName: "LostPassword" */ '@/pages/LostPassword'),
  meta: {
    requiresAuth: false,
  },
};

const CHANGE_PASSWORD = {
  name: 'RecoverPassword',
  path: '/optin/password/change/:hash',
  props: true,
  component: () => import(/* webpackChunkName: "RecoverPassword" */ '@/pages/RecoverPassword'),
  meta: {
    requiresAuth: false,
  },
};

const DASHBOARD = {
  path: '/dashboard',
  redirect: { name: 'courses' },
};

const COURSES = {
  name: 'courses',
  path: '/courses',
  component: () => import(/* webpackChunkName: "Courses" */ '@/pages/Courses'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: true,
  },
};

const NEW_COURSES = {
  name: 'newCourses',
  path: '/new-courses',
  component: () => import(/* webpackChunkName: "Courses" */ '@/pages/NewCourses'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: true,
  },
};

const SURVEY_COURSE_CALENDAR = {
  name: 'surveyCourseCalendar',
  path: '/course/:courseCode/calendar/:calendarId/survey',
  props: true,
  component: () => import(/* webpackChunkName: "CourseCalendarSurvey" */ '@/pages/course/calendar/Survey'),
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const COURSE = {
  name: 'course',
  path: '/course/:courseCode/:calendar?/:wall?/:highlight?',
  props: true,
  component: () => import(/* webpackChunkName: "Course" */ '@/pages/Course'),
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const COURSE_ENROLLMENT = {
  name: 'courseEnrollment',
  path: '/enrollment/course/:preSelectedCourse?',
  props: true,
  component: () => import(/* webpackChunkName: "Enrollment" */ '@/pages/course/enrollment'),
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const EXAM_LANDING = {
  name: 'examLanding',
  path: '/exams/course/:courseCode/:calendarId?',
  props: true,
  component: () => import(/* webpackChunkName: "ExamLanding" */ '@/pages/exam/Landing'),
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const EXAM_STARTED = {
  name: 'examStarted',
  path: '/course/:courseCode/exam/:exam/:calendar?',
  props: true,
  component: () => import(/* webpackChunkName: "ExamStarted" */ '@/pages/exam/Started'),
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const EXAM_RESULT = {
  name: 'examResult',
  path: '/course/:courseCode/exam/:examId/attempt/:attemptId/:animate?/:calendarId?',
  props: true,
  component: () => import(/* webpackChunkName: "ExamResult" */ '@/pages/exam/Result'),
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const CLASS = {
  name: 'class',
  path: '/course/:courseCode/class/:classId?/:calendarId?',
  props: true,
  component: () => import(/* webpackChunkName: "Class" */ '@/pages/class'),
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const CONTACT = {
  name: 'contact',
  path: '/contact/department',
  props: true,
  component: () => import(/* webpackChunkName: "Contact" */ '@/pages/Contact'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: true,
  },
};

const BENEFITS = {
  name: 'Benefits',
  path: '/benefits',
  props: true,
  component: () => import(/* webpackChunkName: "Benefits" */ '@/pages/Benefits'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: true,
  },
};

const LEGACY_DIPLOMA = {
  name: 'legacyDiploma',
  path: '/user/diploma/codigo_diplo/:id/id_diploma/:code',
  props: true,
  component: () => import(/* webpackChunkName: "LegacyDiploma" */ '@/pages/LegacyDiploma'),
  meta: {
    requiresAuth: false,
    history: true,
  },
};

const PROFILE = {
  name: 'Profile',
  path: '/profile',
  props: true,
  component: () => import(/* webpackChunkName: "Profile" */ '@/pages/Profile'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: true,
  },
};

const COMMUNITIES = {
  name: 'Communities',
  path: '/communities',
  redirect: { name: 'Discord' }
};

const DISCORD = {
  name: 'Discord',
  path: '/discord',
  component: () => import(/* webpackChunkName: "Communities" */ '@/pages/Discord'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: false,
  },
};

const CREDITS = {
  name: 'Credits',
  path: '/credits',
  component: () => import(/* webpackChunkName: "Credits" */ '@/pages/Credits'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: true,
  },
};

const ACHIEVEMENT_LANDING = {
  name: 'Roles',
  path: '/roles',
  component: () => import(/* webpackChunkName: "Achievements" */ '@/pages/Roles'),
  meta: {
    layout: 'DefaultLayout',
    requiresAuth: true,
    history: true,
  },
};

const ACHIEVEMENT_CAREER = {
  name: 'careerRoles',
  path: '/roles/career/:career',
  component: () => import(/* webpackChunkName: "Achievements" */ '@/pages/achievement/CareerAchievement'),
  props: true,
  meta: {
    requiresAuth: true,
    history: true,
  },
};

const USER_MERGER = {
  name: 'userMerger',
  path: '/student/merge/:hash',
  props: true,
  component: () => import(/* webpackChunkName: "Merger" */ '@/pages/Merger'),
  meta: {
    requiresAuth: false,
  },
};

const MAINTENANCE = {
  name: 'maintenance',
  path: '/maintenance',
  props: true,
  component: () => import(/* webpackChunkName: "Maintenance" */ '@/pages/Maintenance'),
  meta: {
    requiresAuth: false,
    history: false,
  },
};
const ERROR = {
  name: 'error',
  path: '*',
  props: true,
  component: () => import(/* webpackChunkName: "Error" */ '@/pages/Error'),
  meta: {
    requiresAuth: false,
    history: false,
  },
};

export default [
  HOME,
  LOGIN,
  AUTH,
  LOGOUT,
  LOST_PASSWORD,
  CHANGE_PASSWORD,
  BENEFITS,

  DASHBOARD,
  SURVEY_COURSE_CALENDAR,
  COURSES,
  NEW_COURSES,
  EXAM_LANDING,
  EXAM_RESULT,
  EXAM_STARTED,
  CLASS,
  CONTACT,
  COURSE,
  COURSE_ENROLLMENT,

  LEGACY_DIPLOMA,

  PROFILE,

  COMMUNITIES,
  CREDITS,
  ACHIEVEMENT_LANDING,
  ACHIEVEMENT_CAREER,

  USER_MERGER,
  MAINTENANCE,
  ERROR,
  DISCORD
];
