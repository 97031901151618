import MarkdownIt from 'markdown-it';

export default {
  install: function(Vue) {
    const md = new MarkdownIt({
      linkify: false,
      breaks: false,
      html: false,
    });

    Vue.prototype.$md = function(text) {
      return md.render(text);
    }
  }
}
