import store from '../../store';

export const initializeApp = async (lang) => {
  try {
    await store.dispatch('AuthFirebase/initializeFirebase');
    await store.dispatch('AuthFirebase/authInit');

    await Promise.all([
      store.dispatch('services/api/translation/fetchTranslations', lang, true),
      store.dispatch('services/api/language/setCurrentLanguage', lang),
    ]);

  } catch (error) {
    console.error('Error in initializeApp:', error);
  }
};
