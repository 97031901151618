const settings = {
  version: {
    number: '0.7.26-beta',
    name: 'Sonnenschein',
  },
  clock: {
    sync: 500,
  },
  mobile: {
    breakpoint: 960,
  },
  api: {
    alumni: {
      server: process.env.VUE_APP_API_SERVER,
      session: {
        keepAliveMinutes: 15,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    },
    tenor: {
      server: 'https://api.tenor.com/v1/',
      key: 'WXPCC3DR6KAF',
    },
  },
  websocket: {
    application: 'student',
    server: process.env.VUE_APP_WEBSOCKET_SERVER,
    config: {
      reconnection: true,
      reconnectionAttempts: 1,
      reconnectionDelay: 3000,
      format: 'json',
    },
  },
};

module.exports = settings;
